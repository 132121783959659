define("discourse/plugins/discourse-gitcoin-passport/discourse/templates/connectors/user-card-before-badges/gitcoin-passport-usercard-score", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
      {{#if this.showUHScore}}
    <div class="card-row">
      {{i18n "gitcoin_passport_plugin.unique_humanity_score"}}: {{this.uniqueHumanityScore}}
    </div>
    {{/if}}
  */
  {
    "id": "15vypjie",
    "block": "[[[41,[30,0,[\"showUHScore\"]],[[[1,\"  \"],[10,0],[14,0,\"card-row\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"gitcoin_passport_plugin.unique_humanity_score\"],null]],[1,\": \"],[1,[30,0,[\"uniqueHumanityScore\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-gitcoin-passport/discourse/templates/connectors/user-card-before-badges/gitcoin-passport-usercard-score.hbs",
    "isStrictMode": false
  });
});